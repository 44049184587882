let EnvName = "demo";
let Front_URL = "";
let Back_URL = "";
let v1Url = "";
let limit = "20";
let limitMax = "3000";
let fee = 2500000000000000000;
let decimalvalues = 1000000000000000000;
let toFixed = 8;
let IPFS_IMG = "https://ipfs.io/ipfs";
const singleType = 721;
const multipleType = 1155;
var providercon = null;
let networkVersion = "";
let currencySymbol = "BNB";
let tokenSymbol = "WBNB";
let owntokenSymbol = "DOP";
let livechainid = "";
let NetworkId = "";
let liverpcUrls = "";
let adminAddress = "";
let singleContract = "";
let multipleContract = "";
let tokenAddress = "";
let esctokenAddr = "";
let defaultCollectionSingle = "";
let defaultCollectionMultiple = "";
let userContract721 = "";
let userContract1155 = "";
let toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};
if (EnvName === "production") {
} else if (EnvName == "demo") {
  Front_URL = "https://nft.metadiacdemo.com";
  Back_URL = "https://nftapi.metadiacdemo.com/";
  v1Url = "https://nftapi.metadiacdemo.com/api";
  IPFS_IMG = "https://ipfs.io/ipfs";
  liverpcUrls = "https://data-seed-prebsc-2-s1.binance.org:8545";
  livechainid = 97;
  NetworkId = 97;
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  singleContract = "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731";
  multipleContract = "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598";
  tokenAddress = "0x074CDEcFcea6888B8A63aDeAf941E3BcF8828d40";
  esctokenAddr = "0x35DF7A2D378da4340822893243b1Ea28e401B1D9";
  defaultCollectionSingle = "0x0f480CE7D9fac2086eed251a8A1a5832caf959EF";
  defaultCollectionMultiple = "0x2231116d992753475A1610336946A61e19d390a1";
  networkVersion = "97";
} else {
  Front_URL = "http://localhost:3000";
  Back_URL = "http://localhost:2053/";
  v1Url = "http://localhost:2053/api";
  IPFS_IMG = "https://ipfs.io/ipfs";
  liverpcUrls = "https://data-seed-prebsc-2-s1.binance.org:8545";
  livechainid = 97;
  NetworkId = 97;
  adminAddress = "0x80Aa595c83430288E3A75F4D03bE15b72385420F";
  singleContract = "0x7841DA2CA7cBA260562dC6Ec73d6b9a9354B3731";
  multipleContract = "0xdDCC6B7bE1cAD5A320dfb3730e22250Ed4D7a598";
  tokenAddress = "0x074CDEcFcea6888B8A63aDeAf941E3BcF8828d40";
  esctokenAddr = "0x35DF7A2D378da4340822893243b1Ea28e401B1D9";
  defaultCollectionSingle = "0x0f480CE7D9fac2086eed251a8A1a5832caf959EF";
  defaultCollectionMultiple = "0x2231116d992753475A1610336946A61e19d390a1";
  networkVersion = "97";
}

let key = {
  Front_URL: Front_URL,
  Back_URL: Back_URL,
  vUrl: v1Url,
  decimalvalues: decimalvalues,
  toFixed: toFixed,
  networkVersion: networkVersion,
  currencySymbol: currencySymbol,
  tokenSymbol: tokenSymbol,
  toasterOption: toasterOption,
  limit: limit,
  limitMax: limitMax,
  singleContract: singleContract,
  multipleContract: multipleContract,
  tokenAddress: tokenAddress,
  userContract721: userContract721,
  userContract1155: userContract1155,
  adminAddress: adminAddress,
  singleType: singleType,
  multipleType: multipleType,
  IPFS_IMG: IPFS_IMG,
  providercon: providercon,
  owntokenSymbol: owntokenSymbol,
  esctokenAddr: esctokenAddr,
  fee: fee,
  defaultCollectionSingle: defaultCollectionSingle,
  defaultCollectionMultiple: defaultCollectionMultiple,
  liverpcUrls: liverpcUrls,
  livechainid: livechainid,
  NetworkId: NetworkId
};

export default key;
